











































































































































































































































import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { v4 as uuid } from 'uuid';
import { AgGridVue } from '@ag-grid-community/vue';
import { GridReadyEvent, ValueGetterParams } from '@ag-grid-community/core';
import { isNumber } from 'lodash';
import Tooltip from '@/components/tooltip.vue';
import DataGridFilter from '@/components/data-grid-filter.vue';
import DateRangeFilter from '@/components/date-range-filter.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import AttendanceService from '@/services/crm/attendance.service';
import RouterService from '@/services/router.service';
import AttendanceModel from '@/models/crm/attendance.model';
import { AttendanceOriginEnum } from '@/enums/crm/attendance-origin.enum';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import { IDateRangeConfig } from '@/interfaces/date-range-config.interface';
import ContentDialog from '@/components/content-dialog.vue';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { GridHelper } from '@/utils/helpers/grid-helper';
import { DateHelper } from '@/utils/helpers/date-helper';
import { ArrayHelper } from '@/utils/helpers/array-helper';
import dayjs from '@/plugins/dayjs';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ClientModel from '@/models/crm/client.model';
import CrmChatHistoryMessagesViewer from '@/components/crm/chat-history-messages-viewer.vue';
import CrmOrderDetails from '@/components/crm/order-details.vue';
import CrmOrderEmailView from '@/components/crm/order-email-view.vue';
import ConversationModel from '@/models/crm/conversation.model';
import ConversationService from '@/services/crm/conversation.service';
import ProcessService from '@/services/crm/process.service';
import ProcessModel from '@/models/crm/process.model';
import OrderService from '@/services/crm/order.service';
import ProcessFlowModel from '@/models/crm/process-flow.model';
import OrderModel from '@/models/crm/order.model';
import UserContactInfo from '@/models/crm/user-contact-info.model';

type DataGridFilterConfig = {
  keyword: string | undefined;
  issuance: (Date | undefined)[];
  flow: ProcessFlowModel[];
};

@Component({
  components: {
    Tooltip,
    ContentDialog,
    AgGridWrapper,
    AgGridVue,
    CrmChatHistoryMessagesViewer,
    CrmOrderDetails,
    CrmOrderEmailView,
    DataGridFilter,
    DateRangeFilter,
  },
})
export default class CrmAttendance extends Vue {
  @inject(InjectionIdEnum.CrmAttendanceService)
  private attendanceService!: AttendanceService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  @inject(InjectionIdEnum.CrmProcessService)
  private processService!: ProcessService;

  @inject(InjectionIdEnum.CrmOrderService)
  private orderService!: OrderService;

  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  @InjectReactive('userContactInfo') readonly userContactInfo!: UserContactInfo;

  @InjectReactive('clientType') readonly clientType!: ClientTypeEnum;

  items: AttendanceModel[] = [];

  emptyItems = false;

  loadingItems = false;

  filters: DataGridFilterConfig = {
    keyword: undefined,
    issuance: [undefined, undefined],
    flow: new Array<ProcessFlowModel>(),
  };

  multipleFilterChanged = false;

  flow: ProcessFlowModel = new ProcessFlowModel();

  inputFlow = '';

  processFlowOptions: ProcessFlowModel[] = [];

  processes: ProcessModel[] = [];

  predefinedIssuanceFilterRanges: IDateRangeConfig[] = this.getDateRanges();

  originProperty = {
    [AttendanceOriginEnum.Email]: { icon: 'mdi-email-outline', label: this.$t('crm.view.attendance.origin.email') },
    [AttendanceOriginEnum.Process]: { icon: 'mdi-sitemap', label: this.$t('crm.view.attendance.origin.process') },
    [AttendanceOriginEnum.Calendar]: {
      icon: 'mdi-calendar-month-outline',
      label: this.$t('crm.view.attendance.origin.calendar'),
    },
    [AttendanceOriginEnum.Order]: { icon: 'mdi-cart', label: this.$t('crm.view.attendance.origin.order') },
    [AttendanceOriginEnum.Conversation]: {
      icon: 'mdi-chat',
      label: this.$t('crm.view.attendance.origin.conversation'),
    },
    [AttendanceOriginEnum.SurveyNPS]: { icon: 'mdi-star', label: this.$t('crm.view.attendance.origin.surveynps') },
  };

  dialogConfig: IKeyValue<IDialogConfig> = {
    details: {
      show: false,
      item: new AttendanceModel(),
    },
    chatHistory: {
      show: false,
      conversation: null,
    },
    orderDetail: {
      show: false,
      order: null,
    },
    sendOrderEmail: {
      show: false,
    },
    npsSurvey: {
      show: false,
      survey: null,
    },
  };

  grid: GridReadyEvent | null = null;

  gridColumnsDef = [
    {
      headerName: `${this.$t('crm.view.attendance.grid.date')}`,
      field: 'data',
      valueGetter: (params: ValueGetterParams): string => {
        const date = GridHelper.valueGetter(params);
        const time = params.data.hora;
        const value = dayjs(`${DateHelper.formatToIsoDateString(dayjs(date))}T${time}`);
        return DateHelper.formatToIsoDateTimeString(value);
      },
    },
    {
      headerName: `${this.$t('crm.view.attendance.grid.origin')}`,
      field: 'origem',
    },
    {
      headerName: `${this.$t('crm.view.attendance.grid.attendant')}`,
      field: 'nomeAtendente',
    },
    {
      headerName: `${this.$t('crm.view.attendance.grid.title')}`,
      field: 'titulo',
    },
    {
      headerName: `${this.$t('crm.view.attendance.grid.description')}`,
      field: 'descricao',
      valueGetter: (params: ValueGetterParams): string => {
        const regExp = new RegExp(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g);
        const value = GridHelper.valueGetter(params);
        return value ? value.replace(regExp, ' ') : '';
      },
    },
  ];

  private totalRecords = 0;

  private index = '';

  private readonly itemsPerPage = 100;

  private page = 0;

  private initialDate;

  private sendOrderByEmailLimit = 10;

  ordersPeriod: OrderModel[] = [];

  ordersToSend: OrderModel[] = [];

  private getDateRanges(): IDateRangeConfig[] {
    return [
      {
        name: `${this.$t('global.currentMonth')}`,
        ...DateHelper.getCurrentMonthPeriod(),
      },
      {
        name: `${this.$t('global.lastMonth')}`,
        ...DateHelper.getLastMonthsPeriod(1),
      },
      {
        name: `${this.$t('global.lastThreeMonths')}`,
        ...DateHelper.getLastMonthsPeriod(3),
      },
      {
        name: `${this.$t('global.lastSixMonths')}`,
        ...DateHelper.getLastMonthsPeriod(6),
      },
      {
        name: `${this.$t('global.lastYear')}`,
        ...DateHelper.getLastYearsPeriod(1),
      },
      {
        name: `${this.$t('global.currentYear')}`,
        ...DateHelper.getCurrentYearPeriod(),
      },
    ];
  }

  created(): void {
    this.index = uuid();
  }

  async mounted(): Promise<void> {
    const loader = this.$loading.show();

    try {
      const isProspect = this.clientType === ClientTypeEnum.Prospect;
      const clientId = isProspect ? this.activeClient?.codCliente : this.activeClient?.cnpjCpf;
      const initialDate = dayjs().add(-30, 'day').toDate();
      const finalDate = dayjs().toDate();
      await this.attendanceService
        .generate(
          clientId || this.getClientIdFromRoute(),
          this.activeClient.prospectId || null,
          this.clientType,
          this.index,
          initialDate.toLocaleDateString(),
          finalDate.toLocaleDateString(),
        )
        .then((records) => {
          if (records) {
            this.totalRecords = records;
            this.loadItems(false);
          } else {
            this.emptyItems = true;
          }
        });
      this.ordersPeriod = await this.orderService.getOrdersPeriod(this.activeClient.cnpjCpf, initialDate);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
    this.filters.issuance = [dayjs().add(-30, 'day').toDate(), dayjs().toDate()];

    this.loadProcessFlows();
  }

  async onExport(): Promise<void> {
    if (this.filteredItems.length < this.totalRecords) {
      const loader = this.$loading.show();
      try {
        await this.loadRemainingItemsRecursively(500, this.page * this.itemsPerPage, 1);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }

    if (this.grid) {
      this.grid.api.exportDataAsExcel({
        allColumns: true,
        author: 'IBtech',
        sheetName: 'Atendimentos',
        fileName: AttendanceService.generateAttendanceExportFilename(new Date()),
      });
    }
  }

  onClickRow(item: AttendanceModel): void {
    if (item.origem === AttendanceOriginEnum.Order) {
      this.openOrderDetailDialog(item);
      return;
    }

    if (item.origem === AttendanceOriginEnum.Conversation) {
      this.openHistoryConversationDialog(item.idPrincipal);
      return;
    }

    if (item.origem === AttendanceOriginEnum.SurveyNPS) {
      this.openNpsSurveyDialog(item);
      return;
    }

    this.dialogConfig.details.item = item;
    this.dialogConfig.details.show = true;
  }

  async openHistoryConversationDialog(id: number): Promise<void> {
    const loader = this.$loading.show();
    try {
      const conversation = await this.conversationService.getConversation(id, false);

      this.dialogConfig.chatHistory.conversation = conversation;
      this.dialogConfig.chatHistory.show = true;
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async openNpsSurveyDialog(item: AttendanceModel): Promise<void> {
    const loader = this.$loading.show();
    try {
      this.dialogConfig.npsSurvey.survey = item;
      this.dialogConfig.npsSurvey.show = true;
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async openOrderDetailDialog(selectedOrder: AttendanceModel): Promise<void> {
    const loader = this.$loading.show();
    try {
      if (this.ordersPeriod) {
        const filteredOrder = this.ordersPeriod.find((obj) => obj.codPedido === selectedOrder.descricao);
        if (filteredOrder) {
          const order = await this.orderService.getOrder(
            filteredOrder.idDataSource,
            filteredOrder.codEmpresa,
            filteredOrder.codPedido,
          );
          if (order) {
            this.dialogConfig.orderDetail.order = order;
            this.dialogConfig.orderDetail.show = true;
          }
        }
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  onCloseChatHistoryDialogClose(): void {
    this.dialogConfig.chatHistory.show = false;
    this.dialogConfig.chatHistory.conversation = false;
  }

  onCloseNpsSurveyDialogClose(): void {
    this.dialogConfig.npsSurvey.show = false;
    this.dialogConfig.npsSurvey.survey = false;
  }

  get viewTitle(): string {
    return this.$t('crm.view.attendance.title', { clientType: this.$t(`crm.${this.clientType}`) }).toString();
  }

  get orderDetailDialogTitle(): string {
    return `${this.$t('crm.view.order.dialog.details.title', {
      orderNumber:
        this.dialogConfig?.orderDetail?.order && (this.dialogConfig.orderDetail.order as OrderModel).codPedido,
    })}`;
  }

  get sendOrderEmailDialogTitle(): string {
    const orderNumber = this.ordersToSend.map((item) => `#${item.codPedido}`).join(', ');

    return `${this.$tc('crm.view.order.dialog.sendMail.title', this.ordersToSend.length, {
      orderNumber,
    })}`;
  }

  get chatHistoryDialogTitle(): string {
    const protocol = (this.dialogConfig.chatHistory.conversation as ConversationModel)?.protocolo;
    return this.$t('crm.view.attendance.dialog.chatHistory.title', { protocol }).toString();
  }

  get npsSurveyDialogTitle(): string {
    return this.$t('crm.view.attendance.dialog.npssurvey.title').toString();
  }

  get npsSurveyDialogDateLabel(): string {
    return this.dialogConfig.npsSurvey.survey
      ? (this.dialogConfig.npsSurvey.survey as AttendanceModel)?.observacao
      : this.$t('crm.view.attendance.dialog.npssurvey.dateLabel').toString();
  }

  get monthSeparator(): IKeyValue<string> {
    const data: IKeyValue<string> = {};

    let previousDate: dayjs.Dayjs;
    this.filteredItems.forEach((item, index) => {
      const currentDate = dayjs(item.data);
      if (index === 0) {
        data[index] = currentDate.format('MM/YYYY');
      } else if (previousDate.month() !== currentDate.month() || previousDate.year() !== currentDate.year()) {
        data[index] = currentDate.format('MM/YYYY');
      }
      previousDate = currentDate;
    });
    return data;
  }

  private async loadItems(isInfiniteScrollingLoad: boolean): Promise<void> {
    if (isInfiniteScrollingLoad && (this.items.length >= this.totalRecords || this.loadingItems)) {
      return;
    }

    this.loadingItems = false;

    let offset = 0;
    if (isInfiniteScrollingLoad) {
      offset = this.page * this.itemsPerPage;
      this.page += 1;
    }
    const result = await this.loadData(this.itemsPerPage, offset);
    this.items = [...this.items, ...result];

    this.loadingItems = false;
  }

  private async loadRemainingItemsRecursively(limit: number, startOffset: number, iteration: number): Promise<void> {
    this.loadingItems = true;

    const offset = (iteration - 1) * limit + startOffset;

    const result = await this.loadData(limit, offset);
    this.items = [...this.items, ...result];

    if (this.items.length < this.totalRecords && result.length) {
      await this.loadRemainingItemsRecursively(limit, startOffset, iteration + 1);
    }

    this.loadingItems = false;
  }

  private async loadData(limit: number, offset: number): Promise<AttendanceModel[]> {
    const isProspect = this.clientType === ClientTypeEnum.Prospect;
    const clientId = isProspect ? this.activeClient?.codCliente : this.activeClient?.cnpjCpf;
    const initialDate = this.filters.issuance[0] ? this.filters.issuance[0] : dayjs().add(-30, 'day').toDate();
    const finalDate = this.filters.issuance[1] ? this.filters.issuance[1] : dayjs().toDate();
    return (
      await this.attendanceService.get(
        clientId,
        this.clientType,
        this.index,
        limit,
        offset,
        initialDate.toLocaleDateString(),
        finalDate.toLocaleDateString(),
      )
    ).map((x) => {
      const item = x;

      item.hora = x.hora ? x.hora.substring(0, 5) : '';

      if (item.origem === AttendanceOriginEnum.Order) {
        item.titulo = `Pedido recebido - ${item.titulo}`;
        if (isNumber(item.valor)) {
          item.titulo += ` - ${this.$n(item.valor, 'currency')}`;
        }
      }

      return item;
    });
  }

  private async loadProcessFlows(): Promise<void> {
    const isProspect = this.clientType === ClientTypeEnum.Prospect;
    const clientId = isProspect ? this.activeClient?.codCliente : this.activeClient?.cnpjCpf;
    this.processFlowOptions = await this.processService.getFlows();
    this.processes = await this.processService.getProcesses(clientId, this.clientType);
  }

  get filteredItems(): AttendanceModel[] {
    let filteredItems = this.items;

    if (this.filters.flow && this.filters.flow.length > 0) {
      const processos = Array<number>();
      this.filters.flow.forEach((flow) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        this.processes
          .filter((process) => process.idFluxo === flow.id)
          .forEach((item) => processos.push(item.idProcesso)));
      filteredItems = this.items.filter(
        (item) => (item.origem === AttendanceOriginEnum.Process
            && processos.toString().includes(item.idPrincipal.toString()))
          || item.origem !== AttendanceOriginEnum.Process,
      );
    }

    if (this.filters.keyword) {
      const columnsToSearch = ['index', 'nomeAtendente', 'valor', 'titulo', 'descricao', 'origem'];
      filteredItems = ArrayHelper.filterByKeyword(filteredItems, columnsToSearch, this.filters.keyword);
    }

    if (this.filters.issuance && this.filters.issuance[0]) {
      const start = dayjs(this.filters.issuance[0]);
      filteredItems = filteredItems.filter((item) => dayjs(item.data).isAfter(start) || dayjs(item.data).isSame(start));
    }

    if (this.filters.issuance && this.filters.issuance[1]) {
      const end = dayjs(this.filters.issuance[1]);
      filteredItems = filteredItems.filter((item) => dayjs(item.data).isBefore(end) || dayjs(item.data).isSame(end));
    }

    return filteredItems;
  }

  get activeFilters(): number {
    let active = 0;
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      switch (key) {
        case 'issuance':
          if (this.filters[key] && (this.filters[key][0] || this.filters[key][1])) {
            active += 1;
          }
          break;
        default:
          if (this.filters[key]) {
            active += 1;
          }
      }
    });

    return active;
  }

  async onFilterChange(type: string, field: string): Promise<void> {
    if (type === 'multiple' && !this.multipleFilterChanged) {
      return;
    }

    const loader = this.$loading.show({
      container: this.$refs.loadAttendanceContainer,
      canCancel: false,
      height: 25,
    });

    this.multipleFilterChanged = false;
    if (field === 'dateRange') {
      this.items = [];
      let id = '';
      id = this.clientType === ClientTypeEnum.Prospect ? this.activeClient?.codCliente : this.activeClient?.cnpjCpf;
      const initialDate = this.filters.issuance[0] ? this.filters.issuance[0] : dayjs().add(-30, 'day').toDate();
      const finalDate = this.filters.issuance[1] ? this.filters.issuance[1] : dayjs().toDate();
      await this.attendanceService
        .generate(
          id || this.getClientIdFromRoute(),
          this.activeClient.prospectId || null,
          this.clientType,
          this.index,
          initialDate.toLocaleDateString(),
          finalDate.toLocaleDateString(),
        )
        .then((records) => {
          if (records) {
            this.totalRecords = records;
            this.loadItems(false);
          }
        });
      this.ordersPeriod = await this.orderService.getOrdersPeriod(this.activeClient.cnpjCpf, initialDate);
    } else {
      this.loadItems(true);
    }
    loader.hide();
  }

  onSendEmailOrder(orders: OrderModel[]): void {
    if (!orders.length) {
      return;
    }

    if (orders.length > this.sendOrderByEmailLimit) {
      this.$notify.error(
        `${this.$tc('crm.view.order.sendOrderByEmailLimitExceed', this.sendOrderByEmailLimit, {
          selected: orders.length,
        })}`,
      );

      return;
    }

    this.ordersToSend = orders;
    this.dialogConfig.sendOrderEmail.show = true;
  }

  infiniteScrolling(entries: IntersectionObserverEntry, observer: IntersectionObserver, isIntersecting: boolean): void {
    if (isIntersecting && this.totalRecords > 0) {
      this.loadItems(true);
    }
  }

  private getClientIdFromRoute(): string {
    if (this.clientType === ClientTypeEnum.Prospect) {
      const currentRoute = this.routerService.route();
      return currentRoute.params && currentRoute.params.clientId;
    }
    return this.activeClient.cnpjCpf;
  }
}
